.footer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;
  bottom: 0px;
  background-color: black;
  grid-column-gap: 2em;
}

.footer a:hover {
  text-decoration: underline white;
}

.footer p {
  font-size: 0.8rem;
}

.footertext {
  color: white;
  margin-bottom: 0em;
  font-size: 8px;
}

.footerhead {
  color: white;
  font-size: 20px !important;
  margin-bottom: 1em;
  margin-top: 2em;
}

.enimlogo {
  width: 20%;
  height: auto;
}

@media only screen and (min-width: 900px) {
  .footer {
    grid-template-columns: repeat(4, 1fr);
    bottom: 0px;
    margin-top: 3em;
    padding: 1.5em 10%;
    background-color: black;
    grid-column-gap: 2em;
  }

  .footerhead {
    margin-top: 0px;
  }
}

.heading {
  margin-top: 5%;
  margin-left: 5%;
  color: black;
}

.hr1 {
  margin: 0% 5%;
  background-color: black;
}

.image {
  height: 30vh;
  width: 100%;
  border-radius: 10px;
  border-radius: 15px;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 3em;
}

.dpage {
  padding: 0% 5%;
  margin-top: 0.5em;
}

.textfield {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: solid;
  border-color: #2699fb;
  margin-left: 0px;
  margin-bottom: 3em;
  padding: 0px;
  width: 100%;
}

.textfield::placeholder {
  color: #2699fb;
}

.pbutton {
  background-color: #358ed3;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 0.9em 2em;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
}

.para {
  font-size: 12px;
  margin-top: 3em;
  font-weight: 300;
}

.other {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 5%;
  grid-gap: 3em;
}

.logos {
  margin-top: 0.5em;
  height: 30px;
}

@media only screen and (min-width: 900px) {
  .dpage {
    padding-left: 10%;
    padding-right: 10%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    margin-top: 5em;
    grid-column-gap: 10em;
  }

  .t {
    min-height: 100vh;
  }

  .para {
    font-size: 16px;
  }

  .textfield {
    width: 50%;
  }

  .dimage {
    order: 2;
  }

  .heading {
    margin-right: 10%;
    margin-left: 10%;
  }

  .hr1 {
    margin-right: 10%;
    margin-left: 10%;
  }
  .image {
    height: 35vh;
  }

  .other {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 3fr 3fr;
    padding-left: 10%;
    grid-gap: 3em;
  }
}

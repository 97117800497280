@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Roboto&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100vh;
}

h2,
h1,
h3 {
  font-family: "Playfair Display", serif;
  color: #2699fb;
}

h4 {
  font-family: "Playfair Display", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  background-color: #358ed3;
}

@import url("https://fonts.googleapis.com/css2?family=Ruslan+Display&display=swap");

.headings {
  margin-top: 2em;
  padding: 0% 5%;
}

.eimage {
  max-width: 85%;
  border-radius: 20px;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.38);
  margin-bottom: 1em;
}
.g {
  order: 3;
}
.eimage2 {
  max-width: 85%;
  border-radius: 20px;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.38);
  margin-bottom: 1em;
}

.products {
  padding: 3% 5%;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3em;
  margin-bottom: 3em;
}

.pimage {
  height: 30px;
}

.exp {
  background-color: #ceea9a33;
  padding: 3% 5% 0;
  margin-bottom: 3em;
}

.diff {
  display: grid;
  padding: 0% 5%;
  justify-content: center;
  text-align: center;
}
.diffhead {
  color: #000000;
  font-weight: bold;
  justify-self: center;
}
.difftext {
  font-weight: 5;
  justify-self: center;
  font-size: 10px;
  margin-bottom: 5em;
}
.expcard {
  border-radius: 25px;
  margin-bottom: 1rem;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
}

.cont {
  width: 85%;
  margin: auto;
}

.section1 {
  margin: 0px;
}

h2 {
  font-size: 24px;
  /* margin-left: 10px; */
}

hr {
  /* margin: 10px; */
}

img {
  /* margin-bottom: 1em; */
  justify-self: center;
}
.dimg {
  width: 7em;
  margin-bottom: 0px;

  /* justify-self: center; */
}

p {
  margin-bottom: 2em;
  color: #000000;
}

FormControl {
  border: 20px;
}

@media only screen and (min-width: 900px) {
  .products {
    grid-template-columns: repeat(4, 1fr);
    margin: 0 auto 0 auto;
    justify-content: center;
    margin-top: 5em;
    width: 80%;
    height: 30vh;
    padding: 0px;
  }

  .headings {
    margin: 0 auto 0 auto;
    width: 80%;
    margin-top: 5em;
    padding: 0px;
  }

  .expcont {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    justify-content: space-around;
  }

  .expcard {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .pimage {
    height: 3em;
  }

  .eimage {
    width: 22rem;
    height: 15rem;
  }

  .eimage2 {
    width: 22rem;
    height: 15rem;
    order: 2;
  }

  .diffhead {
    padding: 0px;
  }

  .diff {
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto 0 auto;
    width: 80%;
    grid-column-gap: 5em;
    margin-top: 5em;
    padding: 0px;
  }

  .exp {
    padding: 2em 0px 0;
  }

  .cont {
    width: 65%;
  }
}

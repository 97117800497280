.navlogo {
  width: 70px;
  height: 30px;
}

.search {
  color: #080808;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: solid;
  width: 100%;
}
.navitems {
  color: #2699fb !important;
}

.dropitems {
  color: #2699fb !important;
}

.box {
  background: white;
  border: 0;
  width: 100%;
  padding: 0;
}

@media only screen and (min-width: 900px) {
  .header {
    margin: 0 auto 0 auto;
    padding-left: 10%;
    padding-right: 10%;
    width: 100%;
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 1;
  }

  .navitems {
    color: #2699fb !important;
    font-size: 16px;
    margin-left: 1em;
  }

  .dropitems {
    color: #2699fb !important;
    font-size: 16px;
  }

  .navlogo {
    width: 10em;
    height: 3em;
  }

  .box {
    margin-left: 1em;
  }
}

@media only screen and (min-width: 0px) and (max-width: 900px) {
  .image {
    height: 50vh;
    width: 100%;
  }

  .overlayimage {
    position: absolute;
    opacity: 0.7;
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }

  .overlaytext {
    position: absolute;
    color: white;
    font-size: 16px;
    justify-self: center;
    margin: 0px;
    font-family: "Playfair Display", serif;
  }

  .overlayheading {
    position: absolute;
    color: #ceea9a;
  }

  .photocontainer {
    /* margin-top: 0px;
        padding: 0%;
        width: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center; */

    margin-top: 0px;
    padding: 0%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 900px) {
  .image {
    height: 85vh;
    width: 100%;
  }

  .overlayimage {
    height: 85vh;
    position: absolute;
    opacity: 0.7;
    width: 80%;
    object-fit: cover;
    /* width: 0;
        height: 0; */
    /* border-top: 100px solid; */
    border-top: 85vh solid;
    border-right: 30em solid transparent;
  }

  .overlayheading {
    position: absolute;
    font-size: 160px;
    top: 10%;
    color: #ceea9a;
    left: 10%;
  }

  .overlaytext {
    /* left: 11%; */
    font-size: 56px;
    position: absolute;
    color: white;
    margin-top: 0.5em;
  }

  .photocontainer {
    justify-content: flex-start;
    margin-top: 0px;
    padding: 0%;
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.alt {
  display: flex;
  margin: 3em 10%;
  justify-content: space-between;
}

.list {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  height: max-content;
  width: 30%;
  margin-bottom: 5px;
}
.product {
  font-size: 17px;
  margin: 0;
  cursor: pointer;
  color: #6b6b6b !important;
}

.select {
  font-size: 17px;
  margin: 0;
  cursor: pointer;
  color: blue;
  margin-bottom: 5px;
}

.content {
  margin-bottom: 0.5em;
  color: #6d6d6d;
}

.contentGlow {
  margin-bottom: 0.5em;
  color: #358ed3;
}

.table {
  width: 68%;
  padding: 1rem;
  background-color: white;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  height: 30rem;
  overflow: auto;
}

.table p {
  margin: 5px;
}

.productItem {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .alt {
    flex-direction: column;
    align-items: center;
  }

  .list {
    width: 99%;
  }

  .table {
    width: 99%;
    margin-top: 20px;
  }

  .table h5 {
    font-size: 16px;
    font-weight: 400;
  }

  .table p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.heading {
  font-size: 24px;
  color: black;
  padding: 0% 5%;
  margin-top: 5%;
}

.hr1 {
  background-color: black;
  margin: 0% 5%;
}

.adress {
  margin-top: 0.5em;
  padding: 0% 5%;
  display: grid;
  height: 40vh;
  margin-bottom: 3rem;
}
.form {
  margin: 0% 5% 9%;
}

.textfield {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: solid;
  margin-bottom: 3em;
  padding-left: 5%;
  width: 100%;
}
.textarea {
  resize: vertical;
  min-height: 7em;
  width: 100%;
  border-color: #5c4141;
  margin-bottom: 2em;
}
.maph2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.image {
  margin: auto;
  height: 30vh;
  width: 51vh;
}

.pin {
  display: flex;
  align-items: center;
  width: 180px;
  color: var(--main-blue);
}

.pinicon {
  font-size: 4rem;
}

.pintext {
  font-size: 1.3em;
}

@media screen and (min-width: 799px) {
  .google-map {
    height: 80vh;
  }

  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 15vw;
  }

  .pin-icon {
    font-size: 10vw;
  }
}

@media only screen and (min-width: 900px) {
  .heading {
    margin-left: 10%;
    padding: 0px;
  }
  .hr1 {
    margin-left: 10%;
    margin-right: 10%;
  }

  .cform {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 10%;
    margin-right: 10%;
    grid-gap: 5em;
    margin-top: 5em;
  }

  .adress {
    order: 2;
    padding: 0px;
    margin: 0;
    height: 60vh;
  }

  .form {
    margin: 0px;
  }

  .c {
    min-height: 100vh;
  }

  .hr1 {
    width: 40%;
  }
  .textarea {
    min-height: 15em;
  }

  .image {
    height: 35vh;
    width: 70vh;
  }
}

@import url(https://fonts.googleapis.com/css2?family=Ruslan+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&family=Roboto&display=swap);
.Home_headings__2YMd2 {
  margin-top: 2em;
  padding: 0% 5%;
}

.Home_eimage__1XXGe {
  max-width: 85%;
  border-radius: 20px;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.38);
  margin-bottom: 1em;
}
.Home_g__3AO7G {
  order: 3;
}
.Home_eimage2__2_zfA {
  max-width: 85%;
  border-radius: 20px;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.38);
  margin-bottom: 1em;
}

.Home_products__3x1co {
  padding: 3% 5%;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3em;
  margin-bottom: 3em;
}

.Home_pimage__2bL2J {
  height: 30px;
}

.Home_exp__13xBe {
  background-color: #ceea9a33;
  padding: 3% 5% 0;
  margin-bottom: 3em;
}

.Home_diff__v3adc {
  display: grid;
  padding: 0% 5%;
  justify-content: center;
  text-align: center;
}
.Home_diffhead__32s0o {
  color: #000000;
  font-weight: bold;
  justify-self: center;
}
.Home_difftext__1fi96 {
  font-weight: 5;
  justify-self: center;
  font-size: 10px;
  margin-bottom: 5em;
}
.Home_expcard__3Iw9_ {
  border-radius: 25px;
  margin-bottom: 1rem;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
}

.Home_cont__3U2zW {
  width: 85%;
  margin: auto;
}

.Home_section1__1aZG7 {
  margin: 0px;
}

h2 {
  font-size: 24px;
  /* margin-left: 10px; */
}

hr {
  /* margin: 10px; */
}

img {
  /* margin-bottom: 1em; */
  justify-self: center;
}
.Home_dimg__1ZimX {
  width: 7em;
  margin-bottom: 0px;

  /* justify-self: center; */
}

p {
  margin-bottom: 2em;
  color: #000000;
}

FormControl {
  border: 20px;
}

@media only screen and (min-width: 900px) {
  .Home_products__3x1co {
    grid-template-columns: repeat(4, 1fr);
    margin: 0 auto 0 auto;
    justify-content: center;
    margin-top: 5em;
    width: 80%;
    height: 30vh;
    padding: 0px;
  }

  .Home_headings__2YMd2 {
    margin: 0 auto 0 auto;
    width: 80%;
    margin-top: 5em;
    padding: 0px;
  }

  .Home_expcont__3PIHY {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    justify-content: space-around;
  }

  .Home_expcard__3Iw9_ {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .Home_pimage__2bL2J {
    height: 3em;
  }

  .Home_eimage__1XXGe {
    width: 22rem;
    height: 15rem;
  }

  .Home_eimage2__2_zfA {
    width: 22rem;
    height: 15rem;
    order: 2;
  }

  .Home_diffhead__32s0o {
    padding: 0px;
  }

  .Home_diff__v3adc {
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto 0 auto;
    width: 80%;
    grid-column-gap: 5em;
    margin-top: 5em;
    padding: 0px;
  }

  .Home_exp__13xBe {
    padding: 2em 0px 0;
  }

  .Home_cont__3U2zW {
    width: 65%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 900px) {
  .homecarousel_image__33g-6 {
    height: 50vh;
    width: 100%;
  }

  .homecarousel_overlayimage__2oXb7 {
    position: absolute;
    opacity: 0.7;
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }

  .homecarousel_overlaytext__17DAc {
    position: absolute;
    color: white;
    font-size: 16px;
    justify-self: center;
    margin: 0px;
    font-family: "Playfair Display", serif;
  }

  .homecarousel_overlayheading__1GiHg {
    position: absolute;
    color: #ceea9a;
  }

  .homecarousel_photocontainer__909iK {
    /* margin-top: 0px;
        padding: 0%;
        width: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center; */

    margin-top: 0px;
    padding: 0%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 900px) {
  .homecarousel_image__33g-6 {
    height: 85vh;
    width: 100%;
  }

  .homecarousel_overlayimage__2oXb7 {
    height: 85vh;
    position: absolute;
    opacity: 0.7;
    width: 80%;
    object-fit: cover;
    /* width: 0;
        height: 0; */
    /* border-top: 100px solid; */
    border-top: 85vh solid;
    border-right: 30em solid transparent;
  }

  .homecarousel_overlayheading__1GiHg {
    position: absolute;
    font-size: 160px;
    top: 10%;
    color: #ceea9a;
    left: 10%;
  }

  .homecarousel_overlaytext__17DAc {
    /* left: 11%; */
    font-size: 56px;
    position: absolute;
    color: white;
    margin-top: 0.5em;
  }

  .homecarousel_photocontainer__909iK {
    justify-content: flex-start;
    margin-top: 0px;
    padding: 0%;
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.Header_navlogo__3oXZk {
  width: 70px;
  height: 30px;
}

.Header_search__3Qw1F {
  color: #080808;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: solid;
  width: 100%;
}
.Header_navitems__1GTTZ {
  color: #2699fb !important;
}

.Header_dropitems__oLPfk {
  color: #2699fb !important;
}

.Header_box__1bWa0 {
  background: white;
  border: 0;
  width: 100%;
  padding: 0;
}

@media only screen and (min-width: 900px) {
  .Header_header__27kLq {
    margin: 0 auto 0 auto;
    padding-left: 10%;
    padding-right: 10%;
    width: 100%;
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 1;
  }

  .Header_navitems__1GTTZ {
    color: #2699fb !important;
    font-size: 16px;
    margin-left: 1em;
  }

  .Header_dropitems__oLPfk {
    color: #2699fb !important;
    font-size: 16px;
  }

  .Header_navlogo__3oXZk {
    width: 10em;
    height: 3em;
  }

  .Header_box__1bWa0 {
    margin-left: 1em;
  }
}

.Contact_heading__3YK47 {
  font-size: 24px;
  color: black;
  padding: 0% 5%;
  margin-top: 5%;
}

.Contact_hr1__21gyQ {
  background-color: black;
  margin: 0% 5%;
}

.Contact_adress__S2d7k {
  margin-top: 0.5em;
  padding: 0% 5%;
  display: grid;
  height: 40vh;
  margin-bottom: 3rem;
}
.Contact_form__MDWqZ {
  margin: 0% 5% 9%;
}

.Contact_textfield__EdvF2 {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: solid;
  margin-bottom: 3em;
  padding-left: 5%;
  width: 100%;
}
.Contact_textarea__vp7Kq {
  resize: vertical;
  min-height: 7em;
  width: 100%;
  border-color: #5c4141;
  margin-bottom: 2em;
}
.Contact_maph2__3cNyJ {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.Contact_image__1E1OT {
  margin: auto;
  height: 30vh;
  width: 51vh;
}

.Contact_pin__334cg {
  display: flex;
  align-items: center;
  width: 180px;
  color: var(--main-blue);
}

.Contact_pinicon__1LY2e {
  font-size: 4rem;
}

.Contact_pintext__1TNTL {
  font-size: 1.3em;
}

@media screen and (min-width: 799px) {
  .Contact_google-map__37GOo {
    height: 80vh;
  }

  .Contact_map-h2__WEMnO {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .Contact_pin__334cg {
    width: 15vw;
  }

  .Contact_pin-icon__2beZV {
    font-size: 10vw;
  }
}

@media only screen and (min-width: 900px) {
  .Contact_heading__3YK47 {
    margin-left: 10%;
    padding: 0px;
  }
  .Contact_hr1__21gyQ {
    margin-left: 10%;
    margin-right: 10%;
  }

  .Contact_cform__1ziAq {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 10%;
    margin-right: 10%;
    grid-gap: 5em;
    margin-top: 5em;
  }

  .Contact_adress__S2d7k {
    order: 2;
    padding: 0px;
    margin: 0;
    height: 60vh;
  }

  .Contact_form__MDWqZ {
    margin: 0px;
  }

  .Contact_c__PrPXZ {
    min-height: 100vh;
  }

  .Contact_hr1__21gyQ {
    width: 40%;
  }
  .Contact_textarea__vp7Kq {
    min-height: 15em;
  }

  .Contact_image__1E1OT {
    height: 35vh;
    width: 70vh;
  }
}

.Downloads_heading__1vL9p {
  margin-top: 5%;
  margin-left: 5%;
  color: black;
}

.Downloads_hr1__k3UIc {
  margin: 0% 5%;
  background-color: black;
}

.Downloads_image__3uCj7 {
  height: 30vh;
  width: 100%;
  border-radius: 10px;
  border-radius: 15px;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 3em;
}

.Downloads_dpage__1pBPe {
  padding: 0% 5%;
  margin-top: 0.5em;
}

.Downloads_textfield__11DSm {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: solid;
  border-color: #2699fb;
  margin-left: 0px;
  margin-bottom: 3em;
  padding: 0px;
  width: 100%;
}

.Downloads_textfield__11DSm::-webkit-input-placeholder {
  color: #2699fb;
}

.Downloads_textfield__11DSm::placeholder {
  color: #2699fb;
}

.Downloads_pbutton__lIUmH {
  background-color: #358ed3;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 0.9em 2em;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
}

.Downloads_para__1QE6b {
  font-size: 12px;
  margin-top: 3em;
  font-weight: 300;
}

.Downloads_other__3yyhx {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 5%;
  grid-gap: 3em;
}

.Downloads_logos__2ibbr {
  margin-top: 0.5em;
  height: 30px;
}

@media only screen and (min-width: 900px) {
  .Downloads_dpage__1pBPe {
    padding-left: 10%;
    padding-right: 10%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    margin-top: 5em;
    grid-column-gap: 10em;
  }

  .Downloads_t__2jceC {
    min-height: 100vh;
  }

  .Downloads_para__1QE6b {
    font-size: 16px;
  }

  .Downloads_textfield__11DSm {
    width: 50%;
  }

  .Downloads_dimage__2J-sT {
    order: 2;
  }

  .Downloads_heading__1vL9p {
    margin-right: 10%;
    margin-left: 10%;
  }

  .Downloads_hr1__k3UIc {
    margin-right: 10%;
    margin-left: 10%;
  }
  .Downloads_image__3uCj7 {
    height: 35vh;
  }

  .Downloads_other__3yyhx {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 3fr 3fr;
    padding-left: 10%;
    grid-gap: 3em;
  }
}

.quality_container__29-s1 {
  margin: 5% 10%;
}

.quality_container__29-s1 h1 {
  width: 100%;
  border-bottom: 3px solid;
  padding-bottom: 2rem;
}

.quality_content__33WBa {
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
}

.quality_content__33WBa img {
  width: 30%;
  height: 56vh;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 #bfbfbf;
}

.quality_text__2SkEv {
  width: 65%;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 #bfbfbf;
  padding: 36px;
  padding-bottom: 0;
  align-items: center;
}

.quality_text__2SkEv p {
  margin: 0 0 1.3rem 0;
  font-size: 1.1rem;
}

.quality_contentDark__2TJL6 {
  padding: 2rem 10%;
  border: 3px solid #bdbdbd;
  background: #ceea9a33;
}

.quality_contentDark__2TJL6 h1 {
  width: 100%;
  color: #888;
  font-weight: bold;
  border-bottom: 3px solid;
  padding-bottom: 2rem;
}

.quality_textDark__3C7uI {
  border-radius: 10px;
}

.quality_textDark__3C7uI p {
  margin: 2rem 0 4rem 0;
  font-size: 1.2rem;
}

.quality_contentIcon__3wkiJ {
  display: flex;
  margin-top: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.quality_imgText__35kA2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 33.33%;
}

.quality_imgText__35kA2 p {
  font-size: 1.2rem;
  width: 7rem;
  text-align: center;
  font-weight: bold;
  color: #888;
}

@media only screen and (max-width: 600px) {
  .quality_container__29-s1 {
    margin: 5% 2%;
  }

  .quality_container__29-s1 h1 {
    font-size: 1.6rem;
    padding-bottom: 0.5rem;
    text-align: center;
    width: -webkit-max-content;
    width: max-content;
    max-width: 100%;
    margin: auto;
  }

  .quality_content__33WBa {
    flex-direction: column;
  }

  .quality_content__33WBa img {
    width: 90%;
    margin: auto;
  }

  .quality_text__2SkEv {
    width: 100%;
    box-shadow: none;
    padding-top: 0;
    text-align: center;
    font-size: 1.1rem;
  }

  .quality_contentDark__2TJL6 h1 {
    font-size: 1.8rem;
    text-align: center;
    margin: auto;
    width: -webkit-max-content;
    width: max-content;
    padding-bottom: 1rem;
  }

  .quality_contentDark__2TJL6 p {
    font-size: 1rem;
    text-align: center;
  }

  .quality_imgText__35kA2 {
    flex-basis: 50%;
  }
}


.Honeywell_cardContainer__y4pKn{
    padding: 0% 5%;
}

.Honeywell_cardimage__3PNmP{
    height: 30vh;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 6px 20px 0px rgba(0,0,0,0.20);
    margin-top: 3em;
}
.Honeywell_cardheading__3pNG1{
    color: black;
    margin-top: 1em;
}

.Honeywell_cardtext__17sAD{
    color: #6C6C6C;
    margin-top: 1em;
}


@media only screen and (min-width: 900px) {

    .Honeywell_head__36CLa{
        margin: 0 10%;    
    }

    .Honeywell_cardContainer__y4pKn{
        display: grid;
        margin: 3em 10%;
        padding: 0px;
        grid-template-columns: repeat(4,1fr);
        grid-gap: 2em;
    }

    .Honeywell_cardimage__3PNmP{
        height: 20vh;
    }

    .Honeywell_cardheading__3pNG1{
        margin-top: 1em;
    }
}
.product_searchNcatalogue__CRi47 {
  margin-top: 7%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0% 5%;
  grid-column-gap: 1.5em;
}

.product_textfield__3cFZK {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: solid;
  border-color: #dddddd;
}

.product_button__1ImZn {
  background-color: #358ed3;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 0.8rem 0.5rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.product_heading__162IK {
  color: black;
  font-size: 24px;
  margin-top: 5%;
}

.product_head__1YJdl {
  padding: 0% 5%;
}

.product_link__1M2Vs {
  font-size: 8px;
  color: black;
}

@media only screen and (min-width: 900px) {
  .product_head__1YJdl {
    margin: 0 10%;
    padding: 0px;
  }

  .product_searchNcatalogue__CRi47 {
    margin: 3em 10%;
    padding: 0px;
  }

  .product_button__1ImZn {
    width: 100%;
    padding: 0.9em 2em;
    grid-column-end: 4;
  }
  .product_link__1M2Vs {
    font-size: 12px;
  }
}

.Elementum_container__VV3yE {
  margin: 3em 1em 1em;
  background-color: white;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  padding: 1em;
  border-radius: 15px;
}

.Elementum_content__1w3nE {
  margin-bottom: 0.5em;
  color: #6d6d6d;
}

.Elementum_contentGlow__3c724 {
  margin-bottom: 0.5em;
  color: #358ed3;
}

.Elementum_imagecontainer__REhp0 {
  margin: 3em 1em 1em;
  background-color: white;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.Elementum_image__1zSTj {
  width: 100%;
  height: 20em;
}

@media only screen and (min-width: 900px) {
  .Elementum_elmt__3xkN8 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 5em 10%;
    grid-gap: 2em;
  }
  .Elementum_imagecontainer__REhp0 {
    padding: 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0px;
  }

  .Elementum_container__VV3yE {
    margin: 0px;
  }
}

.Altapure_alt__3OcVo {
  display: flex;
  margin: 3em 10%;
  justify-content: space-between;
}

.Altapure_list__3zJt5 {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  height: -webkit-max-content;
  height: max-content;
  width: 30%;
  margin-bottom: 5px;
}
.Altapure_product__3GQNp {
  font-size: 17px;
  margin: 0;
  cursor: pointer;
  color: #6b6b6b !important;
}

.Altapure_select__QIugY {
  font-size: 17px;
  margin: 0;
  cursor: pointer;
  color: blue;
  margin-bottom: 5px;
}

.Altapure_content__2f2FK {
  margin-bottom: 0.5em;
  color: #6d6d6d;
}

.Altapure_contentGlow__3svze {
  margin-bottom: 0.5em;
  color: #358ed3;
}

.Altapure_table__17mgc {
  width: 68%;
  padding: 1rem;
  background-color: white;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  height: 30rem;
  overflow: auto;
}

.Altapure_table__17mgc p {
  margin: 5px;
}

.Altapure_productItem__19oyX {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .Altapure_alt__3OcVo {
    flex-direction: column;
    align-items: center;
  }

  .Altapure_list__3zJt5 {
    width: 99%;
  }

  .Altapure_table__17mgc {
    width: 99%;
    margin-top: 20px;
  }

  .Altapure_table__17mgc h5 {
    font-size: 16px;
    font-weight: 400;
  }

  .Altapure_table__17mgc p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.Cpc_imageconatiner__3xe28{
    position: relative;
    margin-top: 3em;
    padding: 0% 5%;

    
    
    
}
.Cpc_imagecard1__1Bwza{
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3em;
    background-color: white;
}
.Cpc_imagecard3__3v_nm{
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3em;
    background-color: white;
}
.Cpc_imagecard2__2S-gM{
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3em;
    background-color: black;
   
}

.Cpc_imagecard4__288ys{
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3em;
    background-color: black;
   
}

.Cpc_image__ZJpI8{
    width: 100%;
    height: 100%;
    opacity: 0.3; 
}

.Cpc_imagetext1__vexdh{
        position: absolute;
        justify-self: center;
        color: black;
        font-weight: bold;
        font-size: 24px;
    }

    
 .Cpc_imagetext2__tkxE-{
        position: absolute;
        justify-self: center;
        color: white;
        font-weight: bold;
        font-size: 24px;
    }

.Cpc_overlayimage__1zyix{
        position: absolute;
        justify-self: center;
        opacity: 0.7;
        width: 100%;
        height: 40vh;
        object-fit: cover;
        margin-left: 5%;
        margin-right: 5%;
    }


    @media only screen and (min-width: 900px) {

        .Cpc_cpc__2tDFS{
            /* display: grid;
            grid-template-columns: repeat(2,1fr); */
        }

        .Cpc_imageconatiner__3xe28{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            margin: 3em 10%;
            padding: 0px;
            grid-gap: 0px;
            box-shadow: 0px 6px 20px 0px rgba(0,0,0,0.20);   
        }
        .Cpc_imagecard1__1Bwza{
            margin: 0px;
            border-radius: 10px 0px 0px 0px;
            
            /* order: 2; */
            
        }
        .Cpc_imagecard2__2S-gM{
            margin: 0px;
            border-radius: 0px 10px 0px 0px;

        }
        .Cpc_imagecard3__3v_nm{
            margin: 0px;
            order: 4;
            

        }
        .Cpc_imagecard4__288ys{
            
            margin: 0px;
            border-radius: 0px 0px 0px 10px;
        }

        .Cpc_image__ZJpI8:hover{
            opacity: 1;
        }

        .Cpc_image__ZJpI8:hover .Cpc_imagetext1__vexdh{
            
            visibility: hidden;
            
        }
    }
.Footer_footer__zCZWK {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;
  bottom: 0px;
  background-color: black;
  grid-column-gap: 2em;
}

.Footer_footer__zCZWK a:hover {
  -webkit-text-decoration: underline white;
          text-decoration: underline white;
}

.Footer_footer__zCZWK p {
  font-size: 0.8rem;
}

.Footer_footertext__LBG8B {
  color: white;
  margin-bottom: 0em;
  font-size: 8px;
}

.Footer_footerhead__3OUpU {
  color: white;
  font-size: 20px !important;
  margin-bottom: 1em;
  margin-top: 2em;
}

.Footer_enimlogo__SMVNI {
  width: 20%;
  height: auto;
}

@media only screen and (min-width: 900px) {
  .Footer_footer__zCZWK {
    grid-template-columns: repeat(4, 1fr);
    bottom: 0px;
    margin-top: 3em;
    padding: 1.5em 10%;
    background-color: black;
    grid-column-gap: 2em;
  }

  .Footer_footerhead__3OUpU {
    margin-top: 0px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.pbutton {
  background-color: #358ed3;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 0.8rem 0.5rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}
.usericon {
  position: absolute;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

p {
  font-family: Roboto;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100vh;
}

h2,
h1,
h3 {
  font-family: "Playfair Display", serif;
  color: #2699fb;
}

h4 {
  font-family: "Playfair Display", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  background-color: #358ed3;
}


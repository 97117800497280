.container {
  margin: 3em 1em 1em;
  background-color: white;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  padding: 1em;
  border-radius: 15px;
}

.content {
  margin-bottom: 0.5em;
  color: #6d6d6d;
}

.contentGlow {
  margin-bottom: 0.5em;
  color: #358ed3;
}

.imagecontainer {
  margin: 3em 1em 1em;
  background-color: white;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.image {
  width: 100%;
  height: 20em;
}

@media only screen and (min-width: 900px) {
  .elmt {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 5em 10%;
    grid-gap: 2em;
  }
  .imagecontainer {
    padding: 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0px;
  }

  .container {
    margin: 0px;
  }
}
